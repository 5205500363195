/* global styling */
* {
  box-sizing: border-box;
  background-color: rgb(10, 10, 10);
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
}

.slimesational-logo {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.trapdoor {
  display: flex;
  justify-content: center;
  margin: 20px;
}

/* enter page styling */
.enter-page {
  font-size: 26px;
  margin-top: 160px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.enter-page span {
  margin: 0px;
}

.trapdoor-fade {
  display: flex;
  justify-content: center;
  margin: 20px;
  opacity: 0;
  transition: opacity 2s ease;
}

.trapdoor-fade.loaded {
  opacity: 1;
}

.enter-button-fade {
  display: flex;
  justify-content: center;
  margin: 20px;
  opacity: 0;
  transition: opacity 2s ease;
}

.enter-button-fade.loaded {
  opacity: 1;
}

.enter-button {
  color: white;
  transition: color 0.3s ease;
}

.enter-button:hover {
  color: red;
}

.enter-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* for tablet scaling */
@media (min-width: 768px) {
  .enter-page {
    font-size: 26px;
    margin-top: 0;
  }

  .trapdoor-fade,
  .enter-button-fade {
    margin: 30px;
  }

  .enter-page-container {
    height: 70vh;
  }
}

/* for mobile scaling */
@media (max-width: 767px) {
  .enter-page {
    font-size: 20px;
    margin-top: 160px;
  }

  .trapdoor-fade,
  .enter-button-fade {
    margin: 10px;
  }
}

/* navbar styling */
.navbar {
  color: white;
  display: flex;
  list-style: none;
  justify-content: space-evenly;
  padding-bottom: 5px;
  padding-left: 0;
  margin: 15px;
  gap: 1rem;
  border-style: solid;
  border-top: 0cap;
  border-left: 0cap;
  border-right: 0cap;
  border-width: 1px;
  border-color: rgb(80, 80, 80);
  font-size: 26px;
}

.navbar a {
  text-decoration: none;
  transition: 0.2s ease;
}

.navbar a:visited {
  color: white;
}

.navbar a:hover {
  color: red;
}

.navbar-active {
  color: red !important;
}

/* loader styling */
.loader-text {
  color: white;
  font-size: 26px;
}

/* unused page title styling */
.page-title {
  color: white;
  font-size: x-large;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  font-style: italic;
}

/* homepage/video player styling */
.video-player-container {
  display: grid;
  place-items: center;
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 30px;
  max-width: 100%;
}

.video-player-container h2 {
  margin-bottom: 10px;
  margin-top: 24px;
}

.video-player-container a {
  text-decoration: none;
  transition: 0.2s ease;
  color: red;
}

.video-player-container a:hover {
  color: rgb(120, 120, 120);
}

.video-player {
  max-width: 100%;
  width: 840px;
  height: 473px;
  margin-bottom: 40px;
  border: 2px solid rgb(80, 80, 80);
}

/* links page styling */
.links-list-container {
  color: white;
  font-size: 24px;
  display: flex;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  font-size: 27px;
}

.socials-column,
.videos-column {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 40px;
}

.links-list {
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link-list-headers {
  border-style: solid;
  border-top: 0cap;
  border-left: 0cap;
  border-right: 0cap;
  border-width: 1px;
  border-color: rgb(80, 80, 80);
  font-style: italic;
  margin-top: 0;
  text-align: center;
}

.links-list li {
  margin-bottom: 20px;
  margin-left: 0;
}

.links-list li div {
  display: flex;
}

/* for mobile scaling */
@media (max-width: 767px) {
  .links-list-container {
    flex-direction: column;
    align-items: center;
    margin-top: 26px;
    margin-bottom: 20px;
  }

  .socials-column,
  .videos-column {
    width: 90%;
    margin: 20px 0;
  }
}

/* icon styling within links page*/
.instagram-icon {
  color: #ff00ff;
  padding-right: 5px;
  transition: 0.2s ease;
}

.youtube-icon {
  color: #ff0000;
  padding-right: 4px;
  transition: 0.2s ease;
}

.vimeo-icon {
  color: #1ab7ea;
  margin-right: 5px;
  transition: 0.2s ease;
}

.instagram-icon:hover,
.youtube-icon:hover {
  color: rgb(120, 120, 120);
}

.full-length-video-links,
.alt-text {
  color: white;
}

.full-length-video-links:visited {
  color: white;
}

.full-length-video-links {
  text-decoration: none;
  transition: 0.2s ease;
}

.full-length-video-links:hover,
.youtube-icon:hover,
.vimeo-icon:hover,
.video-link-container:hover .full-length-video-links,
.video-link-container:hover .youtube-icon,
.video-link-container:hover .vimeo-icon {
  color: rgb(120, 120, 120);
}

/* gallery styling */
.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  list-style: none;
  margin-top: 55px;
  margin-bottom: 40px;
  padding-left: 0;
  list-style: none;
}

.gallery img {
  list-style: none;
  max-width: 80%;
  height: auto;
  display: block;
  margin: auto;
  padding-left: 0;
  border: 2px solid rgb(80, 80, 80);
  transition: 0.2s ease;
  margin-bottom: 30px;
  cursor: pointer;
}

.gallery img:hover {
  border: 2px solid red;
}

.enlarged-photo-container {
  font-size: larger;
  color: white;
  display: grid;
  place-items: center;
  margin: auto;
}

/* for desktop scaling */
.enlarged-photo {
  max-width: 45%;
  height: auto;
  display: grid;
  align-items: center;
  margin: 20px auto;
  margin-top: 10px;
  border: 2px solid rgb(80, 80, 80);
}

/* for mobile scaling */
@media (max-width: 767px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  .enlarged-photo {
    max-width: 97%;
    margin: 20px auto;
    margin-top: 10px;
  }
}

.enlarged-photo-text {
  font-size: 24px;
  margin-bottom: 40px;
  text-align: center;
}

.close-button {
  color: red;
  transition: 0.2s ease;
  margin-top: 18px;
  margin-bottom: 0;
  cursor: pointer;
}

.close-button:hover {
  color: rgb(120, 120, 120);
}

/* error page styling */
.error {
  font-size: 26px;
  margin-top: 100px;
  color: white;
  display: grid;
  place-items: center;
}

.error-home-button:visited,
.enter-button:visited,
.white-text {
  color: white;
}

.error-home-button {
  transition: 0.2s ease;
  color: white;
}

.error-home-button:hover {
  color: rgb(120, 120, 120);
}

/* footer styling */
.footer {
  color: rgb(60, 60, 60);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40px;
}

.footer a {
  text-decoration: none;
  transition: 0.2s ease;
  color: rgb(60, 60, 60);
}

.footer a:visited {
  color: rgb(60, 60, 60);
}

.footer a:hover {
  color: red;
}
